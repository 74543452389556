import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { Link, graphql } from "gatsby";
import styled, { css } from "styled-components";
import Img from "gatsby-image";
import logoImg from "../images/logo-icon.png";
import { LogoImg } from "../components/Logo";
import DoddsHeader from "../components/DoddsHeader";

import {
  Layout,
  Article,
  Wrapper,
  Button,
  SectionTitle,
  Header,
  Content,
  Hero
} from "../components";
import {
  StyledH3,
  SecondaryH2,
  SecondaryH1
} from "../components/StyledElements";
import config from "../../config";

// const Content = styled.div`
//   grid-column: 2;
//   ${"" /* box-shadow: 0 4px 120px rgba(0, 0, 0, 0.1); */}
//   border-radius: 1rem;
//   padding: 3rem 6rem;
//   @media (max-width: ${props => props.theme.breakpoints.tablet}) {
//     padding: 3rem 2rem;
//   }
//   @media (max-width: ${props => props.theme.breakpoints.phone}) {
//     padding: 2rem 1.5rem;
//   }
//   overflow: hidden;
// `;

// const Hero = styled.div`
//   grid-column: 2;
//   padding: 3rem 2rem 6rem 2rem;
//   text-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
//   color: ${props => props.theme.colors.grey.dark};

//   @media (max-width: ${props => props.theme.breakpoints.phone}) {
//     padding: 2rem 1rem 4rem 1rem;
//   }

//   p {
//     font-size: 1.68rem;
//     margin-top: -1rem;
//     @media (max-width: ${props => props.theme.breakpoints.tablet}) {
//       font-size: 1.45rem;
//     }
//     @media (max-width: ${props => props.theme.breakpoints.phone}) {
//       font-size: 1.25rem;
//     }
//   }
// `;

// <Layout>
//   <Wrapper>
//     <Helmet title={`Categories | ${config.siteTitle}`} />
//     <Header>
//       <Link to="/">{config.siteTitle}</Link>
//     </Header>
//     <Content>
//       <SectionTitle>Categories</SectionTitle>
//       {group.map(category => (
//         <Title key={category.fieldValue}>
//           <Link to={`/categories/${kebabCase(category.fieldValue)}`}>{category.fieldValue}</Link> (
//           {category.totalCount})
//         </Title>
//       ))}
//     </Content>
//   </Wrapper>
// </Layout>

const IndexPage = props => (
  <Layout>
    <Wrapper>
      <Helmet title={`About Us | ${config.siteTitle}`} />
      <div
        css={css`
          grid-area: header;
        `}
      >
        <DoddsHeader />
      </div>
      <Content>
        <SecondaryH1>About Us</SecondaryH1>
        <p>
          {`Fantasy Name Machine started as a simple idea: What if we could provide simple tools for pen and paper RPG players and GMs? From that idea - sprang forth the Campaign Companion Series.`}
        </p>
        <p>
          {`We've had fun (and continue to have fun) coming up with clever content for tabletop RPG players of all stripes, but now we want to be able to provide more content - faster! So we've decided to dip our toes into Machine learning and see if that bears some fruit.`}
        </p>
        <p>
          {`Fantasy Name Machine gets it's name from two things: Fantasy Names (duh) and Machine Learning. We made simple (for now) Machine Learning algorithm that we fed a lot of our favorite fantasy names and now it comes up with unique
          names! Some may leave a little to be desired - but we've played with this for some time and as you'll see - some of them are pure gold. The best part is it will only get better! We plan on adding additional Machine Learning algorithms for other content and 
          will soon add a way for the community to participate! Let the Journey Begin!`}
        </p>
        <Link
          to="/generator"
          style={{ display: "block", textAlign: "center", margin: "1em" }}
        >
          <Button big>
            <svg
              width="1792"
              height="1792"
              viewBox="0 0 1792 1792"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1764 11q33 24 27 64l-256 1536q-5 29-32 45-14 8-31 8-11 0-24-5l-453-185-242 295q-18 23-49 23-13 0-22-4-19-7-30.5-23.5t-11.5-36.5v-349l864-1059-1069 925-395-162q-37-14-40-55-2-40 32-59l1664-960q15-9 32-9 20 0 36 11z" />
            </svg>
            Go Generate Names
          </Button>
        </Link>
      </Content>
    </Wrapper>
  </Layout>
);

export default IndexPage;

// IndexPage.propTypes = {
//   data: PropTypes.shape({
//     allMdx: PropTypes.shape({
//       nodes: PropTypes.array.isRequired
//     })
//   }).isRequired
// };

// export const IndexQuery = graphql`
//   query IndexQuery {
//     allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
//       nodes {
//         excerpt(pruneLength: 200)
//         timeToRead
//         fields {
//           slug
//         }
//         frontmatter {
//           title
//           date(formatString: "MM/DD/YYYY")
//           categories
//           primaryColor
//           banner {
//             ...bannerImage640
//           }
//         }
//       }
//     }
//   }
// `;
